import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import './new-home.css'


const NewHome = () =>{
    return (
    <>
            <Header />
                <section className="new_home">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mb-2 px-3">
                        <div className="newhome_box">
                            <div className="newhome_box_head">
                                <h2 className="text-center">Neuroradiology Brain MRI</h2>
                            </div>
                            <div className="newhome_box_content">
                                <h4>Learning Module</h4>
                                <p>Solve Cases and learn how to identify key features and practice making the right diagnostic inference</p>
                                <Link to="/learning-module" className="btn view_btn1 text-white mt-2">VIEW</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-2 px-3">
                        <div className="newhome_box">
                            <div className="newhome_box_head">
                                <h2 className="text-center">Neuroradiology Brain MRI</h2>
                            </div>
                            <div className="newhome_box_content">
                                <h4>Case Collection</h4>
                                <p>View case images to familiarize yourself with various diseases and their presentations</p>
                                <Link to="/case-collection" className="btn view_btn1 text-white mt-2">VIEW</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default NewHome