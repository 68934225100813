const Terms = () =>{
    return (
        <div>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="Generator" content="Microsoft Word 15 (filtered)" />
          <style dangerouslySetInnerHTML={{__html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:\"Oswald Light\";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\ttext-align:justify;\n\tline-height:115%;\n\tborder:none;\n\tpadding:0in;\n\tfont-size:11.0pt;\n\tfont-family:\"Times New Roman\",serif;}\nh1\n\t{margin-top:16.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:0in;\n\ttext-align:justify;\n\tline-height:112%;\n\tpage-break-after:avoid;\n\tborder:none;\n\tpadding:0in;\n\tfont-size:12.0pt;\n\tfont-family:\"Times New Roman\",serif;}\n.MsoPapDefault\n\t{text-align:justify;\n\tline-height:115%;\n\tborder:none;\n\tpadding:0in;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n" }} />
          <div className="WordSection1">
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', background: 'white'}}>
              <p className="MsoNormal" align="left" style={{marginBottom: '5.0pt', textAlign: 'left', background: 'white'}}><b><span lang="EN">&nbsp;</span></b></p>
              <p className="MsoNormal" align="center" style={{marginBottom: '5.0pt', textAlign: 'center', background: 'white'}}><b><span lang="EN" style={{color: 'black'}}>Terms of Use</span></b></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt', background: 'white'}}><span lang="EN">This
                  is a legal agreement between the you (“<b><i>User</i></b>” or “<b>you</b>”) and
                  Galileo CDS Inc., a Delaware corporation (“<b><i>Galileo </i></b>”) that sets
                  forth the terms under which you may use the Galileo Educational Platform (“<b><i>Galileo
                      Platform</i></b>”), Galileo Software, and any related services provided by or
                  on behalf of Galileo (collectively, the “<b><i>Software and Services</i></b>”).
                  This agreement will be referred to throughout this document as the “<b><i>Terms
                      of Use</i></b>” and governs your use of the Software and Services.
                  Notwithstanding anything herein to the contrary, if you have entered into a
                  separate agreement with Galileo for the provision of the Services, the terms of
                  such separate agreement will govern your use of the Services.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt', background: 'white'}}><b><span lang="EN">By installing or using any of the Software and Services, you represent
                    to Galileo that you have read, understood and agree to these Terms of Use.</span></b></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt', background: 'white'}}><b><span lang="EN">&nbsp;</span></b></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '6.0pt 6.0pt 6.0pt 6.0pt'}}>
              <h1 style={{marginLeft: '.25in', textIndent: '-.25in'}}><a name="_w5ux3dsfeny7" /><span lang="EN">1.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">Software and Services.</span></h1>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.1.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Access</span></u><span lang="EN">. </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Subject to User's
                  strict compliance with these Terms of Use, Galileo hereby grants to User a
                  non-exclusive, revocable, limited, non-sublicensable, non-transferable license
                  solely for the Term to access and use the Galileo Platform, solely for the
                  following purposes:</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '49.5pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN"><span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>(i)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">solely for the purpose of training and education of
                  medical practitioners; </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.2.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Restrictions</span></u><span lang="EN">. </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">User will not, and
                  will not permit any of its Personnel or any Third Party to: </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '27.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(a)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">reverse engineer, decompile, disassemble or otherwise attempt to
                  discover the source code, object code or underlying structure, ideas or
                  processes or methods of the Galileo Materials;</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(b)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">modify, translate, or create derivative works based on the Galileo
                  Materials;</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(c)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">use the Galileo Materials other than in accordance with any Agreement
                  between you and Galileo and in compliance with all applicable laws and
                  regulations;</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(d)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">run or use any processes that run or are activated while User is not
                  logged on to the Galileo Materials or that “crawl,” “scrape, “ or “spider” the
                  Galileo Materials;</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(e)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">use the Galileo Materials in any manner that:</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '49.5pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN"><span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>(i)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">is harmful, fraudulent, deceptive, threatening,
                  abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or
                  otherwise objectionable (including without limitation, accessing any computer,
                  computer system, network, Software, or Data without authorization, breaching
                  the security of another user or system, or attempting to circumvent any user
                  authentication or security process);</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN"><span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>(ii)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">impersonates any person or entity, including
                  without limitation any Personnel of Galileo ; or </span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN"><span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>(iii)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">contains a virus, Trojan horse, worm, time bomb,
                  unsolicited bulk, commercial, or “spam” message, malware, or other harmful
                  computer code, file, or program (including without limitation, password
                  guessing programs, decoders, password gatherers, keystroke loggers, cracking
                  tools, packet sniffers, or encryption circumvention programs); or</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Any such forbidden
                  use of any of the Software and Services in violation of this Section 1.2 will
                  result in the automatic termination of your license to use the Software and
                  Services.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.3.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Intended User. </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">You agree to use
                  the Software and Services only in a manner consistent with your professional,
                  ethical, regulatory, statutory and other legal requirements. </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.4.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Not a Substitute for Medical Judgment. </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">The Software and
                  Services are not to be used as a substitute for medical judgment, advice,
                  diagnosis or treatment of any health condition or problem. No statements made
                  in the Software and Services have been evaluated by the Food and Drug
                  Administration.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">The content on the
                  Galileo Platform is provided for educational purposes only and is provided for
                  use by medical professionals. It should not be used for self-diagnosis or
                  self-treatment. It is not intended as, nor should it be, a substitute for
                  independent professional medical care. We recommend that you consult your own
                  physician regarding any diagnosis, imaging interpretation or course of
                  treatment.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Galileo does not
                  guarantee the accuracy of the information provided, nor does it assume any
                  legal liability or responsibility for the accuracy, currency or completeness of
                  the information.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Medical
                  professionals must make their own independent assessment before suggesting a
                  diagnosis or recommending or instituting a course of treatment. The Galileo
                  Platform should not in any way be seen as a replacement for consultation with
                  colleagues or other sources, nor as a substitute for conventional training and
                  study.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">If you are not
                  medically qualified and are registering as a layperson, you further acknowledge
                  that the content on the Galileo Platform is provided for educational purposes
                  only, and is provided for use by medical professionals. You agree to use the
                  information solely for your own private educational purposes and further agree
                  not to rely on the information in any way.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">&nbsp;</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.5.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Updates. </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Galileo may update
                  the Terms of Use from time to time without providing notice to the User.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.6.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Ownership and Reservation of Rights </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">The Software and
                  Services, including, but not limited to, any images, photographs, animations,
                  video, audio, music, and text incorporated thereon and therein, and the user
                  documentation, are proprietary to Galileo, or one or more of its licensors, and
                  are protected by law, including without limitation, under United States
                  copyright laws and international treaty provisions. Except for the rights
                  explicitly granted to you in these Terms of Use or in a current License and
                  Development Agreement between you and Galileo, all right, title and interest in
                  the Software and Services are reserved and retained by Galileo, and Galileo does
                  not transfer to you any right, title or interest in the Software and Services,
                  any element thereof or content made available thereon. You do not acquire any
                  ownership rights in the Software and Services as a result of using it or
                  agreeing to these Terms of Use.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.7.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Trademarks </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Galileo and all
                  related logos are trademarks of Galileo. No right, title, license, or interest
                  to any such trademark is granted hereunder, and you agree that no such right,
                  title, license, or interest shall be asserted by you with respect to any such
                  trademark. You shall not use Galileo’s or its licensors’ names or marks or
                  employee names, or adaptations thereof, in any advertising, promotional or
                  sales literature without Galileo’s prior written consent. </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.8.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Collection and Use of Information</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">You acknowledge
                  and agree that Galileo may, directly or indirectly through the services of
                  third parties, collect and store information regarding use of the Software and
                  Services and about equipment through which the Software and Services are
                  accessed and used.&nbsp; You agree that the Galileo may use such information for any
                  purpose related to any use of the Software and Services by you or on your
                  equipment, including but not limited to:</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">(i) improving the
                  performance of the Software and Services or developing updates; and </span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">(ii) verifying
                  your compliance with these Terms of Use and enforcing Galileo’s rights,
                  including all intellectual property rights in and to the Software and Services.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.9.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Termination</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">You may terminate
                  these Terms of Use by ceasing to use the Software and Services.&nbsp; Galileo may
                  immediately terminate these Terms of Use without notice if you breach any
                  provision of these Terms of Use. Upon termination of these Terms of Use, the
                  license granted in these Terms of Use shall also terminate, and you shall cease
                  using the Software and Services and the associated documentation.&nbsp; Sections
                  1.2, 1.3, 1.4, 1.6, 1.7, 1.10, 1.11, 1.12, 1.13, 1.14, 1.15 and 1.16 will
                  survive termination.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.10.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Disclaimer of Warranty</span></u><span lang="EN"> </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">THE GALILEO
                  PLATFORM IS LICENSED “AS-IS” AND WITH ALL FAULTS AND DEFECTS, AND YOU BEAR THE
                  SOLE RISK AND RESPONSIBILITY OF USING IT. Galileo GIVES OR MAKES NO WARRANTIES,
                  GUARANTEES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
                  WARRANTIES FOR INFORMATION, DATA OR CONTENT MAINTENANCE OR STORAGE, UPTIME OR
                  UNINTERRUPTED ACCESS, OR ANY WARRANTY OF ACCURACY, CORRECTNESS, PRECISION,
                  TIMELINESS, THOROUGHNESS, COMPLETENESS, USE OR APPLICATION, ADEQUACY AND
                  SUITABILITY. YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS
                  WHICH THESE TERMS OF USE CANNOT CHANGE. HOWEVER, TO THE MAXIMUM EXTENT
                  PERMITTED BY LAWS APPLICABLE TO YOU IN YOUR JURISDICTION, Galileo AND ITS
                  VENDORS, LICENSORS AND THIRD-PARTY PROVIDERS, EXPRESSLY EXCLUDE THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                  NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING,
                  COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
                  FOREGOING, THE LICENSOR PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO
                  REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE WILL ACHIEVE ANY INTENDED
                  RESULTS OR BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, OR
                  SYSTEMS.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.11.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Limitation On and Exclusion of Remedies and
                    Damages </span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">GALILEO’S AND ITS
                  VENDORS’ TOTAL CUMULATIVE LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR
                  DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR
                  RELATING TO THESE TERMS OF USE SHALL NOT EXCEED THE FEES PAID TO GALILEO, IF
                  ANY, FOR THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE THE CLAIM FIRST AROSE.
                  IN NO EVENT SHALL GALILEO, NOR ANY OF ITS SUBSIDIARIES, DIVISIONS, AFFILIATES,
                  AGENTS, REPRESENTATIVES, THIRD-PARTY PROVIDERS OR LICENSORS BE LIABLE FOR ANY
                  INDIRECT, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES OR LOST PROFITS, EVEN IF
                  GALILEO OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
                  STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                  CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
                  YOU. THIS LIMITATION APPLIES TO ANYTHING RELATED TO THE GALILEO PLATFORM OR
                  THIRD-PARTY PROGRAMS; AND CLAIMS FOR BREACH OF CONTRACT, BREACH OF WARRANTY,
                  GUARANTEE OR CONDITION, STRICT LIABILITY, NEGLIGENCE, OR OTHER TORT TO THE
                  EXTENT PERMITTED BY APPLICABLE LAW. IT ALSO APPLIES EVEN IF GALILEO KNEW OR
                  SHOULD HAVE KNOWN ABOUT THE POSSIBILITY OF THE DAMAGES.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">THE LIMITATIONS
                  SET FORTH IN THIS SECTION SHALL APPLY EVEN IF YOUR REMEDIES UNDER THESE TERMS
                  OF USE FAIL OF THEIR ESSENTIAL PURPOSE.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.12.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Notices</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Any notice or
                  communication permitted or required to Galileo will be in writing and will be
                  addressed to the Parties as follows:</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.75in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Galileo CDS Inc.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">E-mail:
                  education@galileocds.com</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">Attention:
                  Abhijeet Pradhan </span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.13.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Assignments</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">You shall not
                  assign or otherwise transfer any of your rights, or delegate or otherwise
                  transfer any of your obligations or performance, under these Terms of Use, in
                  each case whether voluntarily, involuntarily, by operation of law, or
                  otherwise, without Galileo’s prior written consent, which consent Galileo may
                  give or withhold in its sole discretion. No delegation or other transfer will
                  relieve you of any of your obligations or performance under these Terms of Use.
                  Any purported assignment, delegation, or transfer in violation of this section
                  is void. Galileo may freely assign or otherwise transfer all or any of its
                  rights, or delegate or otherwise transfer all or any of its obligations or
                  performance, under these Terms of Use without your consent. These Terms of Use
                  are binding upon and inure to the benefit of the parties hereto and their
                  respective permitted successors and assigns.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.14.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">No Third-Party Beneficiaries</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">These Terms of Use
                  are for the sole benefit of the parties hereto and their respective successors
                  and permitted assigns and nothing herein, express or implied, is intended to or
                  shall confer on any other person any legal or equitable right, benefit, or
                  remedy of any nature whatsoever under or by reason of these Terms of Use.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.15.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">Severability</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">If any term or provision
                  of these Terms of Use is invalid, illegal, or unenforceable in any
                  jurisdiction, such invalidity, illegality, or unenforceability shall not affect
                  any other term or provision of these Terms of Use or invalidate or render
                  unenforceable such term or provision in any other jurisdiction.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.16.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">No Waiver</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal"><span lang="EN">The failure to require performance of any
                  provision of these Terms of Use will not affect Galileo’s right to require
                  performance at any other time after that, nor will a waiver by Galileo of any
                  breach or default of these Terms of Use, or any provision of these Terms of
                  Use, be a waiver of any subsequent breach or default or a waiver of the
                  provision itself.&nbsp; </span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.17.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN" style={{color: 'black'}}>Usage data</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal"><span lang="EN">Usage Data includes general information about
                  how You interact with the web pages and websites on the Platforms. This is
                  collected using cookies (or similar technologies) placed by us and others -
                  cookies are small pieces of data sent from a website and stored in a web
                  browser, and which may assign an anonymous identifier to the user. </span></p>
              <p className="MsoNormal"><span lang="EN">Usage Data includes:</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '27.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(a)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">pages you visited on our websites</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(b)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">how you came to the website</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(c)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">which particular buttons or links on the site you clicked</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(d)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">whether you have been to the site before</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(e)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">where you are located (city and country)</span></p>
              <p className="MsoNormal" style={{marginLeft: '.25in', textIndent: '-.25in'}}><span lang="EN">(f)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN">inferences that we draw from cookies placed on your browser</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal"><span lang="EN">Galileo uses Usage Data to understand the
                  online behavior of our users. In particular, using web analytics services on
                  the Platforms enables us to measure, collect, analyze and report on Usage Data
                  for the purposes of understanding and optimizing users’ experiences on the
                  Platforms. It also enables us to focus our marketing activities, which, in
                  turn, help us to develop the Platforms.</span></p>
              <p className="MsoNormal"><span lang="EN">Geographical location information contained in
                  the Usage Data enables us to tailor your experience on the Platforms by
                  displaying different content based on your location, such as local advertising
                  offers, and content in your local language.</span></p>
              <p className="MsoNormal"><span lang="EN">We may also use Usage Data for targeted
                  advertising and remarketing (sometimes called retargeting) to better target ads
                  to users. Retargeting uses cookies to identify users who visit a website
                  showing a particular brand of goods or services and then targets advertisements
                  of that brand to that user on other websites. </span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.2in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginLeft: '.2in', textIndent: '-.2in'}}><span lang="EN">1.18.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><u><span lang="EN">User communication</span></u></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal"><span lang="EN">We use your User Information for communicating
                  with you in relation to the Galileo Platform, including contacting you in
                  relation to your learning, and notifying you of administrative matters, such as
                  changes to this privacy policy or alterations to the Terms of Use. </span></p>
              <p className="MsoNormal"><span lang="EN">These communications occur either directly
                  through our application or using third-party communication providers (e.g.
                  Mailchimp, SurveyMonkey, Streak, etc).</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '6.0pt 6.0pt 6.0pt 6.0pt'}}>
              <h1 style={{marginLeft: '.25in', textIndent: '-.25in'}}><a name="_3uuri9u1bg14" /><span lang="EN">2.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span lang="EN">Definitions </span></h1>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '.5in', marginRight: '0in'}}>
              <p className="MsoNormal"><u><span lang="EN">Definitions</span></u><span lang="EN"> For
                  purposes of the Terms of Use, the following terms have the following meanings:</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Algorithm</i>”
                  means (a) an artificial intelligence model, algorithm, process, or technique,
                  (b) any Software that embodies such model, algorithm, process, or technique,
                  and (c) any Intellectual Property in the foregoing clauses (a) or (b).</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '31.5pt', marginRight: '0in'}}>
              <p className="MsoNormal"><span lang="EN">“<i>Galileo Materials</i>” means the Galileo
                  Educational Platform, Galileo Software, and Algorithms created by Galileo.</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">“<i>Galileo Educational Platform</i>” means
                  Galileo’s software-as-a-service platform (including all updates, modifications
                  and new releases) for training and educating medical professionals including
                  residents and students in medical image reading and analysis.</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">“<i>Galileo Software</i>” means Galileo’s
                  proprietary Software (including all updates, modifications and new releases)
                  including the Galileo Educational Platform and Algorithms.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '30.0pt', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Commercialize</i>”
                  or “<i>Commercializing</i>” means to sell, offer to sell, have sold,
                  distribute, or license, in each case to a Third Party.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Develop</i>”
                  or “<i>Developing</i>” means to access, store, use, modify, train, validate,
                  obtain Regulatory Approvals for, create derivative works from, and track the
                  quality or performance of, but not to Commercialize. </span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Personnel</i>”
                  means a Party’s employee, faculty member, officer, director, consultant, legal
                  advisor, technical advisor, business advisor, or representative.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Regulatory
                    Approvals</i>” means, with respect to a product in any country or jurisdiction,
                  any approval, registration, license, or authorization from a Regulatory
                  Authority in such country or other jurisdiction that is reasonably necessary to
                  market and sell a product in such country or jurisdiction.</span></p>
              <p className="MsoNormal" style={{marginBottom: '5.0pt'}}><span lang="EN">“<i>Third Party</i>”
                  means any entity or individual other than Galileo or User or either of
                  Galileo’s or User's Personnel.</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt'}}>
              <p className="MsoNormal" style={{marginBottom: '10.0pt'}}><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal" style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '17.0pt', marginLeft: '0in'}}><span lang="EN">&nbsp;</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt', marginLeft: '2.0in', marginRight: '0in'}}>
              <p className="MsoNormal" style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '17.0pt', marginLeft: '0in'}}><span lang="EN">&nbsp;</span></p>
            </div>
            <div style={{border: 'none windowtext 1.0pt', padding: '5.0pt 5.0pt 5.0pt 5.0pt'}}>
              <p className="MsoNormal" style={{marginBottom: '10.0pt'}}><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
              <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
            </div>
          </div>
        </div>
      );

}
export default Terms