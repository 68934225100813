export const HomePageAccess = {
  FULL_ACCESS: "FULL_ACCESS",
  NO_ACCESS: "NO_ACCESS",
  PARTIAL_ACCESS: "PARTIAL_ACCESS",
  PARTIAL_VERIFY_ACCESS: "PARTIAL_VERIFY_ACCESS",
};

export const CASE_LIMIT = 10;
export const PARTIAL_CASE_LIMIT = 3;

export const CASE_STATUS = {
  COMPLETED: "completed",
  PENDING: "tbd",
};
