import axios from "axios";
import { toast } from "react-toastify";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  setDoc,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  limit,
  startAt,
  orderBy,
  startAfter,
  getDoc
} from "firebase/firestore";
import { db, auth, storage } from "../firebase";
import { CASE_LIMIT, PARTIAL_CASE_LIMIT } from "../constants";


function randomString(length) {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function generateCaseAndUserId(case_id, email){
    case_id = case_id.trim().split(" ").join("")
    let finalStr = ''
    let emailSubStr = email.replace(/[^a-zA-Z0-9 ]/g, '')
    let concatStr = case_id + emailSubStr
    if(concatStr.length < 20){
      let randomStr = randomString(20 - concatStr.length)
      finalStr = concatStr + randomStr 
    }
    else{
      finalStr = concatStr.slice(0, 20)
    }
    return finalStr
}

function generateEmailStringId(email){
  let emailid = ""
  // if(email && email != ''){
    let substring = email.replace(/[^a-zA-Z0-9 ]/g, '')
    if(substring.length < 20){
      let randomStr = randomString(20 - substring.length)
      emailid = substring + randomStr 
    }
    else{
      emailid = substring.slice(0, 20)
    }
  // }
  return emailid
}

export const getIPAddress = async () => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  } catch (error) {
    console.log(error);
  }
};
export const getDisplayCasesNeuro = async() => {
  try{
    let diseasesCategoryQuery = query(collection(db, "display_cases_neuro"));

    const querySnapshotDisplayCasesNeuro = await getDocs(diseasesCategoryQuery);

    let displayCasesNeurodata = [];
    querySnapshotDisplayCasesNeuro.forEach((doc) => {
      displayCasesNeurodata.push(doc.data());
    });

    let finaldata = [];

    displayCasesNeurodata.forEach((ele)=>{
      let findCategory = finaldata.length > 0 ? finaldata.find((fd)=>{
        return fd.disease_category.toLowerCase() == ele.dc.toLowerCase()
      }): false;

      if(!findCategory) {
        let cases = displayCasesNeurodata.filter((caseData) => {
          return caseData.kddx.toLowerCase() == ele.kddx.toLowerCase()
        });

        finaldata.push({
          disease_category: ele.dc,
          diseases: [
            {
              disease_name: ele.kddx,
              cases: cases
            }
          ]
        })
      }
      else{
        let findDisease = findCategory.diseases.length>0 ?
        findCategory.diseases.find((fcd)=>{
          return fcd.disease_name.toLowerCase() == ele.kddx.toLowerCase()
        }): false;
        
        if(!findDisease){
          let cases = displayCasesNeurodata.filter((caseData) => {
            return caseData.kddx.toLowerCase() == ele.kddx.toLowerCase()
          });
          findCategory.diseases.push({
              disease_name: ele.kddx,
              cases: cases
          })
        }
      }
    })
    // console.log("finaldata---", finaldata)
    return finaldata

  }
  catch(err){
    console.log(err);
  }
}
export const getAllCasesAccordingToDiseasesCategory = async() =>{
  try{
    let casesNeuroQuery = query(collection(db, "cases_neuro"), where("corrected", "in", ["Y", "y"]));

    const querySnapshotCasesNeuro = await getDocs(casesNeuroQuery);

    let casesNeurodata = [];
    querySnapshotCasesNeuro.forEach((doc) => {
      casesNeurodata.push(doc.data());
    });


    let diseasesCategoryQuery = query(collection(db, "display_cases_neuro"));

    const querySnapshotDisplayCasesNeuro = await getDocs(diseasesCategoryQuery);

    let displayCasesNeurodata = [];
    querySnapshotDisplayCasesNeuro.forEach((doc) => {
      displayCasesNeurodata.push(doc.data());
    });

    let finaldata = [];
    // console.log("displayCasesNeurodata",displayCasesNeurodata)
    displayCasesNeurodata.forEach((ele)=>{

      let findCategory = finaldata.length > 0 ? finaldata.find((fd)=>{
        return fd.disease_category.toLowerCase() == ele.dc.toLowerCase()
      }): false;

      if(!findCategory) {
        let cases = casesNeurodata.filter((caseData) => {
          return caseData.known_ddx.toLowerCase() == ele.kddx.toLowerCase()
        });

        finaldata.push({
          disease_category: ele.dc,
          folder_link: ele.folder,
          diseases: [
            {
              disease_name: ele.kddx,
              cases: cases
            }
          ]
        })
      }
      else{
        let findDisease = findCategory.diseases.length>0 ?
        findCategory.diseases.find((fcd)=>{
          return fcd.disease_name.toLowerCase() == ele.kddx.toLowerCase()
        }): false;
        
        if(!findDisease){
          let cases = casesNeurodata.filter((caseData) => {
            return caseData.known_ddx.toLowerCase() == ele.kddx.toLowerCase()
          });
          findCategory.diseases.push({
              disease_name: ele.kddx,
              cases: cases
          })
        }

      }

    })
    return finaldata
    // console.log("finaldata---", finaldata[2])

  }
  catch(err){
    console.log(err);
  }
}
export const checkDomainAndHandleCases = async (values, id) => {
  try {
    const domain = values.email.split("@")[1];
    let matchedDomain = null;

    const querySnapshot = await getDocs(collection(db, "domains"));
    querySnapshot.forEach((doc) => {
      if (doc.data().domain === domain) {
        matchedDomain = doc.data();
      }
    });

    if (!matchedDomain) {
      // Scenario 4: Create record in users and temp_users collection

      let emailid = generateEmailStringId(values.email);

      const docRef = doc(db, "users", emailid);

      const docSnap = await getDoc(docRef);
      
      let resp = docSnap.data();

      if(resp){

      let user = await addDoc(collection(db, "users"), {
        fname: values.firstName,
        lname: values.lastName,
        user_email: values.email,
        role: "student",
        date_created: Timestamp.fromDate(new Date()),
        guid: id,
        verify: false,
      });

      // console.log("USER", user.id)

      let temp_user = await addDoc(collection(db, "temp_users"), {
        guid: user.id,
        allowed: "P",
        date_start: Timestamp.fromDate(new Date()),
      });
      }
      else{
        let user = await setDoc(doc(db, "users", emailid), 
        { 
          fname: values.firstName,
          lname: values.lastName,
          user_email: values.email,
          role: "student",
          date_created: Timestamp.fromDate(new Date()),
          guid: id,
          verify: false,
        }
      );
      let temp_user = await addDoc(collection(db, "temp_users"), {
        // guid: user.id,
        guid: emailid,
        allowed: "P",
        date_start: Timestamp.fromDate(new Date()),
      });
      }
          
      return true;
    } else {
      if (matchedDomain.allowed === "Y") {
        // Scenario 1: Create record in users collection

        let emailid = generateEmailStringId(values.email);

        const docRef = doc(db, "users", emailid);

        const docSnap = await getDoc(docRef);
        
        let resp = docSnap.data();

        if(resp){
        await addDoc(collection(db, "users"), {
          fname: values.firstName,
          lname: values.lastName,
          user_email: values.email,
          role: "student",
          date_created: Timestamp.fromDate(new Date()),
          guid: id,
          verify: false,
        });
        }
        else {
          let user = await setDoc(doc(db, "users", emailid), 
          {
              fname: values.firstName,
              lname: values.lastName,
              user_email: values.email,
              role: "student",
              date_created: Timestamp.fromDate(new Date()),
              guid: id,
              verify: false,
            }
        );
        }

        return true;
      } else if (matchedDomain.allowed === "N") {
        // Scenario 2: Delete user from auth
        let user = auth.currentUser;
        user.delete();
        // TODO: Send email to Admin
        return false;
      } else if (matchedDomain.allowed === "P") {
        // Scenario 3: Create record in users collection


        let emailid = generateEmailStringId(values.email);


        const docRef = doc(db, "users", emailid);

        const docSnap = await getDoc(docRef);
        
        let resp = docSnap.data();
        if(resp){
        await addDoc(collection(db, "users"), {
          fname: values.firstName,
          lname: values.lastName,
          user_email: values.email,
          role: "student",
          date_created: Timestamp.fromDate(new Date()),
          guid: id,
          verify: false,
        });
        }
        else{
          let user = await setDoc(doc(db, "users", emailid), 
          {
            fname: values.firstName,
            lname: values.lastName,
            user_email: values.email,
            role: "student",
            date_created: Timestamp.fromDate(new Date()),
            guid: id,
            verify: false,
          }
        );
        }

        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserDoc = async (userCredential) => {
  let user = null;

  const cred = await userCredential;

  let authId;
  if (cred.user || cred.currentUser) {
    authId = cred.user ? cred.user.uid : cred.currentUser.uid;
  }

  // Get User from users collection
  const userQuery = query(collection(db, "users"), where("guid", "==", authId));

  const querySnapshot = await getDocs(userQuery);
  querySnapshot.forEach(async (docSnap) => {
    user = {
      id: docSnap.id,
      ...docSnap.data(),
    };
  });

  if (user) {
    let image = await getUserProfilePic(user.id);
    if (image) {
      user = {
        ...user,
        image,
      };
    }

    const healthcareToken = await getHealthcareToken(auth);

    user = {
      ...user,
      healthcareToken,
    };
  }

  return user;
};

export const createDBLogs = async (values, user) => {
  const currentIP = await getIPAddress();

  if (values && currentIP) {
    let { id } = user;

    // Get User from user_login_log collection
    const userLogQuery = await query(collection(db, "user_login_log"), where("guid", "==", id));
    const querySnapshot = await getDocs(userLogQuery);

    // Update Log
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((docSnap) => {
        let data = docSnap.data();
        updateDoc(doc(db, "user_login_log", docSnap.id), {
          ...data,
          login_details: [
            ...data.login_details,
            {
              ip: currentIP,
              date_time: Timestamp.fromDate(new Date()),
            },
          ],
        });
      });
    } else {
      addDoc(collection(db, "user_login_log"), {
        guid: id,
        login_details: [
          {
            ip: currentIP,
            date_time: Timestamp.fromDate(new Date()),
          },
        ],
      });
    }
  }
};

export const checkDomainAccess = async (values, user) => {
  const domain = values.email.split("@")[1];
  let matchedDomain = null;
  let access = "";

  const querySnapshot = await getDocs(collection(db, "domains"));
  querySnapshot.forEach((doc) => {
    if (doc.data().domain === domain) {
      matchedDomain = doc.data();
    }
  });

  if (!matchedDomain) {
    let temp_user = null;

    const userQuery = await query(collection(db, "temp_users"), where("guid", "==", user.id));

    const querySnapshot = await getDocs(userQuery);
    querySnapshot.forEach((docSnap) => {
      temp_user = docSnap.data();
    });

    if (!temp_user) {
      access = "";
    } else {
      access = verifyAccess(temp_user, user, false);
    }
  } else {
    access = verifyAccess(matchedDomain, user, true);
  }
  return access;
};

export const verifyAccess = ({ allowed, date_start, date_end }, user, fromDomainList) => {
  date_start = date_start ? date_start.toDate() : null;
  date_end = date_end ? date_end.toDate() : null;

  let access = allowed;

  if (fromDomainList) {
    if (access === "Y") {
      // Dates Case
      const currentDate = new Date();

      if (!date_start && !date_end) {
        access = "Y";
      } else {
        if (currentDate > date_start && currentDate < date_end) {
          access = "Y";
        } else {
          access = "P";
        }
      }
    }
  }
  return access;
};

export const validateFirebaseLink = async (body) => {
  try {
    const passResetUrl = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;

    if (body.password) {
      body = {
        oobCode: body.code,
        newPassword: body.password,
      };
    } else {
      body = {
        oobCode: body.code,
      };
    }

    const res = await fetch(passResetUrl, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });

    const data = await res.json();
    return data;
  } catch (error) {
    toast.error("Oops!! Link has expired");
    return false;
  }
};

export const updateUserDocument = async (body) => {
  try {
    let user = await getUserDoc(auth);

    if (user) {
      await updateDoc(doc(db, "users", user.id), body);
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateProfilePic = async (user, pic) => {
  try {
    let storageRef = ref(storage, `/user-profile/${user.id}`);
    let uploaded = await uploadBytes(storageRef, pic);

    if (uploaded) {
      let image = await getUserProfilePic(user.id);
      return image;
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return false;
  }
};

export const getUserProfilePic = async (id) => {
  try {
    console.log("ID", id)
    const profilePic = await getDownloadURL(ref(storage, `/user-profile/${id}`));

    if (profilePic) {
      return profilePic;
    } else {
      return "";
    }
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const getDataFromCollection_original = async (coll, filter = null) => {
  try {
    let Query;

    const count = await getCollectionDocCounts(coll, filter);
    if(coll==='cases_neuro'){
      if (filter) {
        if (filter.value !== "") {
          Query = query(
            collection(db, coll),
            where(filter.key, "==", filter.value),
            where("corrected", "in", ["Y", "y"]),
            orderBy(filter.orderBy),
            startAt(filter.startAt),
            limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
          );
        } else {
          Query = query(collection(db, coll), where("corrected", "in", ["Y", "y"]), orderBy(filter.orderBy), startAt(filter.startAt), limit(CASE_LIMIT));
        }
      } else {
        Query = query(collection(db, coll), where("corrected", "in", ["Y", "y"]));
      }
    }
    else{
      if (filter) {
        if (filter.value !== "") {
          Query = query(
            collection(db, coll),
            where(filter.key, "==", filter.value),
            orderBy(filter.orderBy),
            startAt(filter.startAt),
            limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
          );
        } else {
          Query = query(collection(db, coll), orderBy(filter.orderBy), startAt(filter.startAt), limit(CASE_LIMIT));
        }
      } else {
        Query = query(collection(db, coll));
      }
    }

    const querySnapshot = await getDocs(Query);

    let data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    let attemptedCase;

    if (filter && filter.user) {
      data = await Promise.all(
        data.map(async (d) => {
          attemptedCase = await getAttemptedCaseDoc({ id: d.case_id, user: filter.user });

          if (attemptedCase) {
            d = {
              ...d,
              attempted: true,
              observation_score: attemptedCase.observation_score,
              inference_score: attemptedCase.inference_score,
            };
          }

          return d;
        }),
      );
    }

    return { data, count };
  } catch (error) {
    console.log(error);
  }
};


export const getDataFromCollection = async (coll, filter = null) => {
  try {
    let Query;
    console.log("filter", filter)
    const count = await getCollectionDocCounts(coll, filter);
    if(coll==='cases_neuro'){
      if (filter) {
        if (filter.value !== "") {
          if(filter.value == "Y"){
            Query = query(
              collection(db, coll),
              where(filter.key, "==", filter.value),
              where("corrected", "in", ["Y", "y"]),
              orderBy(filter.orderBy),
              // startAt(filter.startAt),
              // limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
              limit(PARTIAL_CASE_LIMIT),
            );
          }
          else {
            Query = query(
              collection(db, coll),
              where(filter.key, "==", filter.value),
              where("corrected", "in", ["Y", "y"]),
              orderBy(filter.orderBy),
              // startAt(filter.startAt),
              // limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
            );
          }

        } else {
          Query = query(collection(db, coll), where("corrected", "in", ["Y", "y"]), orderBy(filter.orderBy), 
          // startAt(filter.startAt),
          //  limit(CASE_LIMIT)
           );
        }
      } else {
        Query = query(collection(db, coll), where("corrected", "in", ["Y", "y"]));
      }
    }
    else{
      if (filter) {
        if (filter.value !== "") {
          Query = query(
            collection(db, coll),
            where(filter.key, "==", filter.value),
            orderBy(filter.orderBy),
            startAt(filter.startAt),
            limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
          );
        } else {
          Query = query(collection(db, coll), orderBy(filter.orderBy), startAt(filter.startAt), limit(CASE_LIMIT));
        }
      } else {
        Query = query(collection(db, coll));
      }
    }

    const querySnapshot = await getDocs(Query);

    let data = [];

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    if(coll == "cases_neuro"){
      function compare( a, b ) {
        if ( a.order_no < b.order_no ){
          return -1;
        }
        if ( a.order_no > b.order_no ){
          return 1;
        }
        return 0;
      }
      

      data = data.sort(compare)
      var size = CASE_LIMIT; var arrayOfArrays = [];
      for (var i=0; i < data.length; i+=size) {
           arrayOfArrays.push(data.slice(i, i+size));
      }
      let index = filter.page ? (+filter.page - 1): 0;
      data = arrayOfArrays[index]
    }

    let attemptedCase;

    if (filter && filter.user) {
      data = await Promise.all(
        data.map(async (d) => {
          attemptedCase = await getAttemptedCaseDoc({ id: d.case_id, user: filter.user });

          if (attemptedCase) {
            d = {
              ...d,
              attempted: true,
              observation_score: attemptedCase.observation_score,
              inference_score: attemptedCase.inference_score,
            };
          }

          return d;
        }),
      );
    }

    return { data, count };
  } catch (error) {
    console.log(error);
  }
};

export const calculatePagination = async (coll, filter) => {
  try {
    const perPageData = [];

    const pages = Math.ceil(filter.count / CASE_LIMIT);
    console.log("Start after", perPageData.length > 0 ? perPageData[perPageData.length - 1].end : "")
    let snap;
    // for (let i = 1; i <= pages; i++) {
      if(coll=='cases_neuro'){
        if (filter.value !== "") {
          if(filter.value == "Y"){
            snap = await getDocs(
              query(
                collection(db, coll),
                where(filter.key, "==", filter.value),
                where("corrected", "in", ["Y", "y"]),
                orderBy(filter.orderBy),
                startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
                limit(PARTIAL_CASE_LIMIT),
              ),
            );
          }
          else {
            snap = await getDocs(
              query(
                collection(db, coll),
                where(filter.key, "==", filter.value),
                where("corrected", "in", ["Y", "y"]),
                orderBy(filter.orderBy),
                startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
                // limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
              ),
            );
          }

        } else {
          snap = await getDocs(
            query(
              collection(db, coll),
              where("corrected", "in", ["Y", "y"]),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              // limit(CASE_LIMIT),
            ),
          );
        }
      }
      else{
        if (filter.value !== "") {
          snap = await getDocs(
            query(
              collection(db, coll),
              where(filter.key, "==", filter.value),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
            ),
          );
        } else {
          snap = await getDocs(
            query(
              collection(db, coll),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(CASE_LIMIT),
            ),
          );
        }
      }


      let d = [];
      snap.forEach((doc) => {
        d.push(doc.data());
      });

      function compare( a, b ) {
        if ( a.order_no < b.order_no ){
          return -1;
        }
        if ( a.order_no > b.order_no ){
          return 1;
        }
        return 0;
      }
      

      let sortedArr = d.sort(compare)

      const chunkSize = filter.value == "Y" ? PARTIAL_CASE_LIMIT: CASE_LIMIT;
      console.log("chunkSize",chunkSize)
      for (let i = 0, j = 0; i < sortedArr.length; i += chunkSize, j++) {
          const chunk = sortedArr.slice(i, i + chunkSize);
          // do whatever

        perPageData.push({
          index: j,
          start: chunk[0].case_id,
          end: chunk[chunk.length - 1].case_id,
        });
      }
      console.log("sortedArr", sortedArr)

      if (d.length) {
        // perPageData.push({
        //   index: i,
        //   start: d[0].case_id,
        //   end: d[d.length - 1].case_id,
        // });
      }
    // }
      console.log("perPageData", perPageData)
    return perPageData;
  } catch (error) {
    console.log(error);
  }
};

export const calculatePagination_original = async (coll, filter) => {
  try {
    const perPageData = [];

    const pages = Math.ceil(filter.count / CASE_LIMIT);

    let snap;
    for (let i = 1; i <= pages; i++) {
      if(coll=='cases_neuro'){
        if (filter.value !== "") {
          snap = await getDocs(
            query(
              collection(db, coll),
              where(filter.key, "==", filter.value),
              where("corrected", "in", ["Y", "y"]),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
            ),
          );
        } else {
          snap = await getDocs(
            query(
              collection(db, coll),
              where("corrected", "in", ["Y", "y"]),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(CASE_LIMIT),
            ),
          );
        }
      }
      else{
        if (filter.value !== "") {
          snap = await getDocs(
            query(
              collection(db, coll),
              where(filter.key, "==", filter.value),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(filter.value === "Y" ? PARTIAL_CASE_LIMIT : CASE_LIMIT),
            ),
          );
        } else {
          snap = await getDocs(
            query(
              collection(db, coll),
              orderBy(filter.orderBy),
              startAfter(perPageData.length > 0 ? perPageData[perPageData.length - 1].end : ""),
              limit(CASE_LIMIT),
            ),
          );
        }
      }


      let d = [];
      snap.forEach((doc) => {
        d.push(doc.data());
      });
      if (d.length) {
        perPageData.push({
          index: i,
          start: d[0].case_id,
          end: d[d.length - 1].case_id,
        });
      }
    }

    return perPageData;
  } catch (error) {
    console.log(error);
  }
};

export const getCollectionDocCounts = async (coll, filter = null) => {
  try {
    let Query;
    if(coll=='cases_neuro'){
      if (filter && filter.value !== "") {
        if (filter.value === "Y") {
          Query = query(collection(db, coll), where(filter.key, "==", filter.value), where("corrected", "in", ["Y", "y"]), limit(PARTIAL_CASE_LIMIT));
        } else {
          Query = query(collection(db, coll), where(filter.key, "==", filter.value), where("corrected", "in", ["Y", "y"]));
        }
      } else {
        Query = query(collection(db, coll), where("corrected", "in", ["Y", "y"]));
      }
    }
    else{
      if (filter && filter.value !== "") {
        if (filter.value === "Y") {
          Query = query(collection(db, coll), where(filter.key, "==", filter.value), limit(PARTIAL_CASE_LIMIT));
        } else {
          Query = query(collection(db, coll), where(filter.key, "==", filter.value));
        }
      } else {
        Query = query(collection(db, coll));
      }
    }


    const count = (await getDocs(Query)).size;
    return count;
  } catch (error) {
    console.log(error);
  }
};

export const createStudentLog = async (data) => {
  try {

    let studentLogId = generateCaseAndUserId(data.case_id, data.user_id);

    const docRef = doc(db, "student_log", studentLogId);

    const docSnap = await getDoc(docRef);
    
    let resp = docSnap.data();

    let created

    if(resp){
      created = await addDoc(collection(db, "student_log"), data);
    }
    else{
      created = await setDoc(doc(db, "student_log", studentLogId), data);
    }
    return created ? true : false;
  } catch (error) {
    console.log(error);
  }
};

export const getAttemptedCaseDoc = async (data) => {
  try {
    let existingCase = null;

    let Query = query(
      collection(db, "student_log"),
      where("case_id", "==", data.id),
      where("user_id", "==", data.user),
    );

    const snap = await getDocs(Query);
    snap.forEach(async (doc) => {
      existingCase = doc.data();
    });

    return existingCase;
  } catch (error) {
    console.log(error);
  }
};

export const setInitialCaseValues = (body) => {
  const { attemptedC, setFindingValues, setLocationValues, setImpressions, diseases } = body;

  for (let key in attemptedC) {
    if (key.includes("_entered")) {
      if (key.split("_entered")[0] === "location") {
        setLocationValues(attemptedC[key]);
      } else if (key.split("_entered")[0].startsWith("acceptable_diagnosis")) {
        setImpressionsValues(key.split("_entered")[0], attemptedC[key], setImpressions, diseases, attemptedC);
        delete attemptedC[key];
      } else {
        setFindingValues((prev) => ({
          ...prev,
          [key.split("_entered")[0]]: attemptedC[key],
        }));
      }
    }
  }
};

const setImpressionsValues = (key, value, setImpressions, diseases, attemptedC) => {
  let localKey = key === "acceptable_diagnosis1" ? "first" : key === "acceptable_diagnosis2" ? "second" : "third";

  let link = diseases.data.filter((dis) => dis.disease_name.toLowerCase() === value.toLowerCase())[0]
    ?.disease_reference;

  setImpressions((prev) => ({
    ...prev,
    [localKey]: {
      value,
      link: link ? link : "",
      result: attemptedC[`${key}_eval`],
    },
  }));
};

export const getHealthcareToken = async (auth) => {
  const requestUrl = `${process.env.REACT_APP_API_URL}/auth/healthcare/token`;

  const res = await fetch(requestUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${auth.currentUser.accessToken}` },
  });

  return res.text();
};
