import React, { useCallback } from "react";

const AllCasesTable = ({ cases, onSelectCase }) => {

  // function compare(a, b) {
  //   if (a.level < b.level) {
  //     return -1;
  //   }
  //   if (a.level > b.level) {
  //     return 1;
  //   }
  //   return 0;
  // }
  

  const handleSelectCase = useCallback(
    (c) => (/*event*/) => {
      onSelectCase(c);
    },
    [onSelectCase],
  );

    // let casesWithLevel = [];
    // let casesWithoutLevel = [];
    //  cases.filter((ele)=>{
    //   if(ele.level){
    //     casesWithLevel.push(ele)
    //   }
    //   else {
    //     casesWithoutLevel.push(ele)
    //   }
    // })

    // let sorted = casesWithLevel.sort(compare)
    // let final = [...sorted, ...casesWithoutLevel]
    let final = cases
  // let correctCases = cases.filter(ele => ele.hasOwnProperty('corrected') ? ele.corrected.toLowerCase()=='y': false)

  return (
    <div className="study-table table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Study ID</th>
            <th scope="col">Modality</th>
            <th scope="col">Level</th>
            <th scope="col">Status</th>
            <th scope="col">Known Diagnosis</th>
            <th scope="col">Observation Performance</th>
            <th scope="col">Diagnosis Performance</th>
          </tr>
        </thead>
        <tbody>
          {final.map((c) => (
            <tr key={c.case_id} className="pointer" onClick={handleSelectCase(c)}>
              <th scope="row">{c.case_id}</th>
              <td>{c.modality}</td>
              <td>{c.level ? c.level.toUpperCase(): ""}</td>
              <td>{c.attempted ? "Completed" : "To be done"}</td>
              <td>{c.attempted && c.known_ddx}</td>
              <td>{c?.observation_score}</td>
              <td className="text-capitalize">{c?.inference_score}</td>
            </tr>
          ))}
          {/* <tr>
              <th scope="row">001037</th>
              <td>MRI</td>
              <td>Completed</td>
              <td>Abscess</td>        
              <td>9/10</td>
              <td>Correct</td>
            </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default AllCasesTable;
