import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import './case-collection.css'
import Accordion from "../Accordion";
import Header from "../../components/Header";
import { fetchAllCases } from "../../redux/action/cases";
import Loader from "../../components/Common/Loader";

const DiseasesCases = () =>{
    return (
        <div className="row">
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Glioma</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Primitive Neuroectodermal Tumor</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Neurofibromatosis (type1, type2)</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Astrocytoma low grade (WHO 11 to 111), (Oligodendroglioma, Giant
                cell)</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Metastasis</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Glioma</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Glioma</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-3 col-md-4 mb-3 px-2">
        <div className="disease_list_box">
            <h3>Glioma</h3>
            <ul className="m-0 p-0">
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
                <li>
                    <a href="#">Example</a>
                </li>
            </ul>
        </div>
    </div>
</div>
    )
}

const CaseCollection = () =>{
    const { allCases } = useSelector(state => state.cases);

    const [selected, setSelected] = useState(0);
    const dispatch = useDispatch()

    const handleClick = (index) => {
        setSelected(index)
    }

    useEffect(()=>{
        dispatch(fetchAllCases())
    }, [])

    // console.log(allCases.data)

    return (
        <>
        <Header />
        <section className="case_collection_section">
        <div className="container">
            {
                allCases.loading ? (
                    <div className="text-center">
                    <Loader />
                    </div>
                ): (
                    <div className="row">
                    <div className="col-md-12">
                    <h1>Diseases arranged by Disease Category</h1>
                        <div className="accordion d-block bg-transparent p-0" id="accordionExample">
                            {
                                allCases.data.map((ele, index)=>{
                                    return (
                                        <Accordion folderlink={ele.folder_link} key={"" + Math.random()}  title={ele.disease_category} index={index} handleClick={handleClick} selected={selected} diseasescases={ele.diseases} />
                                    )
                                })
                            }
                            {/* <Accordion  title="Neoplasia" index={1} handleClick={handleClick} selected={selected} diseasescases={DiseasesCases}   />
                            <Accordion  title="Neoplasia" index={2} handleClick={handleClick} selected={selected} diseasescases={DiseasesCases}   /> */}
                        </div>
                    </div>
                </div>
                )
            }

        </div>
    </section>
    </>
    )
}

export default CaseCollection