// Auth Types

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const ACCESS_TYPE = 'ACCESS_TYPE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';

// Cases Type

export const FETCH_CASES_LOADING = 'FETCH_CASES_LOADING';
export const FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_ERROR = 'FETCH_CASES_ERROR';
export const UPDATE_CASE_PAGE = 'UPDATE_CASE_PAGE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_CASE_ACCESS = 'SET_CASE_ACCESS';
export const ATTEMPT_CASE_LOADING = 'ATTEMPT_CASE_LOADING';
export const ATTEMPT_CASE_SUCCESS = 'ATTEMPT_CASE_SUCCESS';
export const ATTEMPT_CASE_ERROR = 'ATTEMPT_CASE_ERROR';
export const GET_ATTEMPTED_CASE_LOADING = 'GET_ATTEMPTED_CASE_LOADING';
export const GET_ATTEMPTED_CASE_SUCCESS = 'GET_ATTEMPTED_CASE_SUCCESS';
export const GET_ATTEMPTED_CASE_ERROR = 'GET_ATTEMPTED_CASE_ERROR';

// Diseases Type 

export const FETCH_DISEASES_LOADING = 'FETCH_DISEASES_LOADING';
export const FETCH_DISEASES_SUCCESS = 'FETCH_DISEASES_SUCCESS';
export const FETCH_DISEASES_ERROR = 'FETCH_DISEASES_ERROR';


export const SHOW_KNOWN_DDX = 'SHOW_KNOWN_DDX'



export const FETCH_ALL_CASES_LOADING = 'FETCH_ALL_CASES_LOADING';
export const FETCH_ALL_CASES_SUCCESS = 'FETCH_ALL_CASES_SUCCESS';
export const FETCH_ALL_CASES_ERROR = 'FETCH_ALL_CASES_ERROR';