import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Profile from "../pages/profile";
import NeuroRadiology from "../pages/neuro-radiology";
import ProtectedRoute from "./ProtectedRoute";
import NewHome from "../pages/new-home";
import CaseCollection from "../pages/case-collection";
import Terms from "../pages/Terms";


const SuspenseLoader = ({ children }) => {
  return (
    <Suspense fallback={<div style={{ width: "100%", height: "100%" }}>{/**just empty as our pages load fast */}</div>}>
      {children}
    </Suspense>
  );
};

// Load the auth page as separate chunk. This separates it from viewer when viewer redirects here.
const Auth = lazy(() => import("../pages/auth"));

// Load the viewer page as separate chunk. This ensures styling does
// not clash with our app.
const Viewer = lazy(() => import("../pages/viewer"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<NewHome />} />
        <Route path="/case-collection" element={<CaseCollection />} />
        <Route path="/learning-module" element={<Home />} />
        <Route path="/neuro-radiology/:caseId" element={<NeuroRadiology />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/viewer/*"
          index
          element={
            <SuspenseLoader>
              <Viewer />
            </SuspenseLoader>
          }
        />
      </Route>
      <Route path="/terms" element={<Terms />} />

      <Route
        path="/auth"
        element={
          <SuspenseLoader>
            <Auth />
          </SuspenseLoader>
        }
      />

      {/** This has to be last */}
      <Route element={<div>404 Not found!</div>} />
    </Routes>
  );
};

export default AppRoutes;
