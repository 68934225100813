import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import CustomPagination from "../Pagination/CustomPagination";
import AllCasesTable from "../Tables/AllCasesTable";

const CaseAccess = () => {
  const { user, access } = useSelector((state) => state.auth);
  const {
    cases: { loading, error, data, total },
  } = useSelector((state) => state.cases);

  const navigate = useNavigate();

  const baseRoute = window.location.origin;

  const handleSelectCase = useCallback(
    (c) => {
      if (c.folder) {
        const pathIndex = c.folder.indexOf("projects/");
        const path = `/viewer/${c.folder.substring(pathIndex)}`;
        window.open(`${baseRoute}${path}`);
      }

      navigate(`/neuro-radiology/${c.case_id}`);
    },
    [baseRoute, navigate],
  );

  if (loading) {
    return (
      <div className="text-center">
        <Loader />
      </div>
    );
  } else if (data?.length > 0) {
    return (
      <>
        <AllCasesTable cases={data} onSelectCase={handleSelectCase} />
        {total === 0 || access === "P" || (access === "Y" && !user.verify) ? null : <CustomPagination />}
      </>
    );
  } else if (error) {
    return <p className="text-center">{error}</p>;
  } else {
    return "";
  }
};

export default CaseAccess;
