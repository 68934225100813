import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./neuro-radiology.css";
import Header from "../../components/Header";
import Findings from "../../components/NeuroRadiology/Findings";
import Impressions from "../../components/NeuroRadiology/Impressions";
import { attemptCase, fetchCase, fetchDiseases, getAttemptedCase } from "../../redux/action/cases";
import Location from "../../components/NeuroRadiology/Location";
import { findings } from "../../data";
import { CASE_STATUS } from "../../constants";
import Loader from "../../components/Common/Loader";
import { setInitialCaseValues } from "../../utils/helper";
import {
  GreenCheckIconSVG,
} from "../../assets/svgComponents";

const NeuroRadiology = () => {
  const navigate = useNavigate();
  const { caseId } = useParams();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { showKnownDdx } = useSelector((state) => state.cases);

  const {
    diseases,
    singleCase,
    attemptedCase: { loading, attemptedC },
  } = useSelector((state) => state.cases);

  const [impressions, setImpressions] = useState({
    first: { value: "", link: "", result: "" },
    second: { value: "", link: "", result: "" },
    third: { value: "", link: "", result: "" },
  });
  const [findingValues, setFindingValues] = useState({});
  const [locationValues, setLocationValues] = useState([]);
  const [showChecks, setShowChecks] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (caseId && user) {
      dispatch(getAttemptedCase({ id: caseId, user: user.user_email }));
    }
  }, [caseId, user, dispatch]);

  useEffect(() => {
    dispatch(fetchDiseases());
    caseId && dispatch(fetchCase({ page: 1, id: caseId, startAt: "", loading: true, user: user.user_email }));
  }, [dispatch, caseId, user]);

  useEffect(() => {
    if (attemptedC && diseases.data.length > 0) {
      setShowChecks(true);
      setHasSubmitted(true);
      setInitialCaseValues({ attemptedC, setFindingValues, setLocationValues, setImpressions, diseases });
    }
  }, [attemptedC, diseases]);

  const calcObservationScore = () => {
    let score = 0;
    if (singleCase) {
      for (let i in findingValues) {
        if (findingValues[i].toLowerCase() === singleCase[i].toLowerCase()) {
          score++;
        }
      }

      const elm = compareLocation();
      if (elm && elm.filter((el) => el?.parentValue?.toLowerCase() === "correct").length) {
        score++;
      }
    }

    return score;
  };


  const calcInferenceScore = () => {
    let levelArr = []
    if (impressions) {
      for (let i in impressions) {
        levelArr.push(impressions[i].result)
        // if (impressions[i].result === 'correct') {
        //   return impressions[i].result;
        // } else if (impressions[i].result === 'acceptable') {
        //   return impressions[i].result;
        // } else if (impressions[i].result === 'incorrect') {
        //   return impressions[i].result;
        // } else {
        //   return impressions[i].result;
        // }
      }
      var diagnosisBestScore = levelArr.find(level => level.toLowerCase() == 'correct')
      if (diagnosisBestScore) return diagnosisBestScore;

      var diagnosisBestScore = levelArr.find(level => level.toLowerCase() == 'acceptable')
      if (diagnosisBestScore) return diagnosisBestScore;

      var diagnosisBestScore = levelArr.find(level => level.toLowerCase() == 'incorrect')
      if (diagnosisBestScore) return diagnosisBestScore;
    }
  }

  const calcInferenceScoreOld = () => {
    if (impressions) {
      for (let i in impressions) {
        if (impressions[i].result === 'correct') {
          return impressions[i].result;
        } else if (impressions[i].result === 'acceptable') {
          return impressions[i].result;
        } else if (impressions[i].result === 'incorrect') {
          return impressions[i].result;
        } else {
          return impressions[i].result;
        }
      }

    }
  }

  const compareLocation = useCallback(() => {
    if (locationValues.length && singleCase) {
      return singleCase.location.map((el) => {
        let elem = locationValues.filter((e) => e.parent.toLowerCase() === el.parent.toLowerCase())[0];

        if (elem) {
          el = { ...el, parentValue: el.parent.toLowerCase() === elem.parent.toLowerCase() ? "correct" : "incorrect" };

          el.child = el.child.map((c) => {
            let ch = elem.child.filter((e) => e.toLowerCase() === c.toLowerCase())[0];
            return { el: c, val: ch ? "correct" : "incorrect" };
          });
        }

        return el;
      });
    }
  }, [singleCase, locationValues]);

  const handleSubmit = async () => {
    if (
      findings.length === Object.keys(findingValues).length &&
      impressions.first.value !== "" &&
      impressions.second.value !== "" &&
      impressions.third.value !== "" &&
      locationValues.length &&
      singleCase
    ) {
      setShowChecks(true);

      if (!hasSubmitted) {
        const obj = {
          case_id: singleCase.case_id,
          modality: singleCase.modality,
          user_id: user.user_email,
          status: CASE_STATUS.COMPLETED,
          flair_entered: findingValues.flair,
          flair_eval: findingValues.flair.toLowerCase() === singleCase.flair.toLowerCase() ? "correct" : "incorrect",
          t1_entered: findingValues.t1,
          t1_eval: findingValues.t1.toLowerCase() === singleCase.t1.toLowerCase() ? "correct" : "incorrect",
          t2_entered: findingValues.t2,
          t2_eval: findingValues.t2.toLowerCase() === singleCase.t2.toLowerCase() ? "correct" : "incorrect",
          contrast_enhancement_entered: findingValues.contrast_enhancement,
          contrast_enhancement_eval:
            findingValues.contrast_enhancement.toLowerCase() === singleCase.contrast_enhancement.toLowerCase() ? "correct" : "incorrect",
          susceptibility_artifact_entered: findingValues.susceptibility_artifact,
          susceptibility_artifact_eval:
            findingValues.susceptibility_artifact.toLowerCase() === singleCase.susceptibility_artifact.toLowerCase() ? "correct" : "incorrect",
          diffusion_entered: findingValues.diffusion,
          diffusion_eval: findingValues.diffusion.toLowerCase() === singleCase.diffusion.toLowerCase() ? "correct" : "incorrect",
          size_entered: findingValues.size,
          size_eval: findingValues.size.toLowerCase() === singleCase.size.toLowerCase() ? "correct" : "incorrect",
          mass_effect_entered: findingValues.mass_effect,
          mass_effect_eval: findingValues.mass_effect.toLowerCase() === singleCase.mass_effect.toLowerCase() ? "correct" : "incorrect",
          num_lesion_entered: findingValues.num_lesion,
          num_lesion_eval: findingValues.num_lesion.toLowerCase() === singleCase.num_lesion.toLowerCase() ? "correct" : "incorrect",
          dominant_pattern_entered: findingValues.dominant_pattern,
          dominant_pattern_eval:
            findingValues.dominant_pattern.toLowerCase() === singleCase.dominant_pattern.toLowerCase() ? "correct" : "incorrect",
          side_entered: findingValues.side,
          side_eval: findingValues.side.toLowerCase() === singleCase.side.toLowerCase() ? "correct" : "incorrect",
          location_entered: locationValues,
          location_eval: compareLocation(),
          acceptable_diagnosis1_entered: impressions.first.value,
          acceptable_diagnosis1_eval: impressions.first.result,
          acceptable_diagnosis2_entered: impressions.second.value,
          acceptable_diagnosis2_eval: impressions.second.result,
          acceptable_diagnosis3_entered: impressions.third.value,
          acceptable_diagnosis3_eval: impressions.third.result,
          observation_score_total: Object.keys(findingValues).length + 1,
          observation_score: `${calcObservationScore()}/${Object.keys(findingValues).length + 1}`,
          inference_score_total: 3,
          inference_score: calcInferenceScore(),
        };

        const isSubmit = dispatch(attemptCase(obj));

        if (isSubmit) {
          toast.success("Case Attempt Success");
          setHasSubmitted(true);
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      toast.error("Please fill the complete form");
    }
  };

  const [knownDdxReference, setKnownDdxReference] = useState(null)
  useEffect(() => {
    // console.log("IN NEW USE EFFEC")
    // console.log("showChecks--",showChecks)
    // console.log("singleCase--",singleCase)
    // console.log("diseases--",diseases)
    if (showChecks && singleCase && diseases) {
      let disease = diseases.data.filter((dis) => dis.disease_name.toLowerCase() === singleCase.known_ddx.toLowerCase())[0]
      // console.log('disease---', disease)
      if (disease) {
        setKnownDdxReference(disease.disease_reference)
      }
    }
  }, [singleCase, showChecks, diseases])

  // console.log("impressions--",impressions)

  let show_known_ddx = true;
  if (singleCase) {
    for (let ele in impressions) {

      if (impressions[ele].value) {
        if (impressions[ele].value.toLowerCase() == singleCase.known_ddx.toLowerCase()) {
          show_known_ddx = false
        }
      }
    }
  }
  return (
    <>
      <Header />
      <section className="body">
        <div className="container">
          {loading ? (
            <div className="text-center mt-4">
              <Loader />
            </div>
          ) : (
            <div className="row">
              <div className="inner-wrap">
                <div className="heading d-flex justify-content-between">
                  <p>
                    {" "}
                    Brain Pathologies : <a href="/">Study ID : {caseId}</a>
                  </p>
                  {singleCase?.age && (
                    <p>
                      {" "}
                      Patient Age :<a href="/"> {singleCase.age}</a>
                    </p>
                  )}
                </div>
                <div className="findings row">
                  <Findings findingValues={findingValues} setFindingValues={setFindingValues} showChecks={showChecks} />
                  <Location
                    locationValues={locationValues}
                    setLocationValues={setLocationValues}
                    showChecks={showChecks}
                    compareLocation={compareLocation}
                  />
                </div>
                <Impressions impressions={impressions} setImpressions={setImpressions} showChecks={showChecks} />
              </div>
              <div className="next d-flex justify-content-between mt-3 mb-5 align-items-center px-2">
                {(show_known_ddx && singleCase && showChecks) ? <>
                  <p className="mb-0"><span>Known Diagnosis:</span> {singleCase.known_ddx}
                    <GreenCheckIconSVG />
                    {<a href={knownDdxReference ? knownDdxReference : "/"} target="_blank" rel="noreferrer" className='text-decoration-underline mx-2'>Reference</a>}
                  </p>
                </> : <></>}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (hasSubmitted) {
                      navigate("/");
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  {hasSubmitted ? "Back" : "Next"}
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default NeuroRadiology;
