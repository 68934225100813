import React, { useEffect, useRef, useState } from "react";

import "./accordion.css";

import {ReactComponent as DownArrow} from "../../assets/images/down-arrrow.svg";
import { Link } from "react-router-dom";


const CustomDiseaseCases = ({diseases, folderlink}) => {

    const baseRoute = window.location.origin;

    const handleLink = (e, folderlink) =>{
        e.preventDefault()
        e.stopPropagation()
        window.open(folderlink)
        // if (folderlink) {
        //     const pathIndex = folderlink.indexOf("projects/");
        //     const path = `/viewer/${folderlink.substring(pathIndex)}`;
        //     window.open(`${baseRoute}${path}`);
        // }

        // window.open(folderlink, "_blank")
        // window.open()
    }

    return (
        <div className="row">
            {
                diseases.map((disease)=>{
                    return (
                    <div key={"" + Math.random()} className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>{disease.disease_name}</h3>
                            <ul className="m-0 p-0">
                                {
                                    disease.cases.map((caseData, index)=>{
                                        let case_folder_link = caseData.folder
                                        const pathIndex = case_folder_link.indexOf("projects/");
                                        const path = `/viewer/${case_folder_link.substring(pathIndex)}`;
                                        // console.log(caseData)
                                        return (
                                        <li key={"" + Math.random()}>
                                            {/* <Link to={`/neuro-radiology/${caseData.case_id}`}>{caseData.case_id}</Link> */}
                                            <a href={`${baseRoute}${path}`} onClick={(e)=>handleLink(e, `${baseRoute}${path}`)}>{`Case ${index + 1}`}</a>
                                        </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    )
                })
            }
        </div>
    ) 
}

function Accordion(props) {
  const [active, setActive] = useState(true);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    console.log("Height for ", props.title, ": ", height);
  }, [height]);

  function toggleAccordion() {
    setActive(!active);
    props.handleClick(props.index)
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  useEffect(()=>{
      if(props.selected != props.index){
        setHeight("0px");
        setActive(false)
      }
      if(props.selected == props.index){
        setHeight(`${content.current.scrollHeight}px`)
    }
  }, [props.selected])


  return (
    <div className="accordion__section">
      <div
        className={`accordion ${active ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        <p className="accordion__title">{props.title}</p>
        <span style={{ marginLeft: "20px" }}>
            {active ?
            <DownArrow />
              :
            <DownArrow />
            }

            </span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          
        >
            <CustomDiseaseCases diseases={props.diseasescases} folderlink={props.folderlink} />
            {/* <props.diseasescases /> */}
             {/* <div className="row">
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Glioma</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Primitive Neuroectodermal Tumor</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Neurofibromatosis (type1, type2)</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Astrocytoma low grade (WHO 11 to 111), (Oligodendroglioma, Giant
                                cell)</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Metastasis</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Glioma</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Glioma</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-3 px-2">
                        <div className="disease_list_box">
                            <h3>Glioma</h3>
                            <ul className="m-0 p-0">
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                                <li>
                                    <a href="#">Example</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
