import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

const ProtectedRoute = () => {
  const { user, authError } = useSelector((state) => state.auth);
  const hasAccess = user != null;

  // Get redirect from location.
  const { state, key, ...path } = useLocation();

  const redirectParams =
    path?.pathname && path.pathname.length
      ? [`redirect=${path.pathname}${path.hash && path.hash.length ? path.hash : path.search || ""}`]
      : [];

  if (state != null) {
    const encodedState = JSON.stringify(state);
    redirectParams.push(`state=${encodedState}`);
  }

  if (key != null && key !== "default") {
    redirectParams.push(`key=${key}`);
  }

  let redirect = authError && redirectParams.length ? redirectParams.join("&") : null;

  if (!hasAccess) {
    const authUrl = `/auth${redirect ? "?" + redirect : ""}`;

    // Hack to replace url and also navigate to it using router. This forces the page to refresh.
    // Need to refresh so ohif styles are removed as a refresh will ignore the lazy loaded chunk,
    // but the router will not.
    window.location.replace(authUrl);
    return <Navigate to={authUrl} replace={true} />;
  }

  return (
    <>
    <Outlet />
    <div className="auth-footer-layout">
    <div className="bottom-text">
             <ul>
             <li><a target="_blank" href="https://galileocds.com/index.php/our-team/">About us</a></li>

             <li><a target="_blank" href="mailto:education@galileocds.com">Support</a></li>

                              
              <li><a target="_blank" href="/terms">Terms</a></li>

                            
              {/* <li><Link to="/terms">Terms</Link></li> */}

              <li><a target="_blank" href="https://galileocds.com/index.php/manual/">Manual</a></li>
             
             </ul>
         </div>
    </div>

    </>
  )

};

export default ProtectedRoute;
